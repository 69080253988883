.footer-container {
  display: flex;
  width: screen;
  padding: 23px 52px;
  justify-content: space-between;
  height: 140px;
  align-items: flex-end;
  gap: 10px;
  border-radius: 50px 50px 0px 0px;
  background: #ffe9d2;
}

.footer-logo {
  width: 50px;
  height: 50px;
}

.footer-logo-download {
  width: 20px;
  height: 20px;
}

.copyright-logo {
  width: 10px;
  height: 10px;
}

.footer-copyright-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-menu {
  display: flex;
  align-items: flex-end;
  gap: 36px;
}

.footer-home {
  display: flex;
  height: 36px;
  padding: 1px 21px;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  border-radius: 12px;
  background: #80faeb;

  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for color change */

  cursor: pointer; /* Change cursor to hand on hover */
}

.footer-download {
  padding-bottom: 50px;
}
.footer-home:hover {
  background: #93fffa; /* Change color on hover */
  transform: scale(1.05); /* Scale up by 5% on hover */
}

.footer-title {
  color: var(--Purple, #611ff3);
  /* Body 1 */
  font-family: Jura;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: 1200px) {
    font-size: 18px;
  }
}

.footer-copyright {
  color: var(--Purple, #000000);
  /* Body 1 */
  font-family: Jura;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.me-title {
  color: var(--Purple, #611ff3);
  /* Body 1 */
  font-family: Jura;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100px;
}

.navbar-me {
  display: flex;
  height: 34px;
  padding: 1px 21px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #71e4d2;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for color change */
  cursor: pointer; /* Change cursor to hand on hover */
}

.navbar-me:hover {
  background: #93fffa; /* Change color on hover */
  transform: scale(1.05); /* Scale up by 5% on hover */
}

.navbar-project {
  display: flex;
  height: 36px;
  padding: 1px 21px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #71e4d2;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for color change */
  cursor: pointer; /* Change cursor to hand on hover */
}
.navbar-project:hover {
  background: #93fffa; /* Change color on hover */
  transform: scale(1.05); /* Scale up by 5% on hover */
}

.navbar-connect {
  display: flex;
  height: 36px;
  padding: 1px 21px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #71e4d2;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for color change */
  cursor: pointer; /* Change cursor to hand on hover */
}

.navbar-connect:hover {
  background: #93fffa; /* Change color on hover */
  transform: scale(1.05); /* Scale up by 5% on hover */
}


@media (max-width: 1024px) {
  .footer-menu,
  .footer-copyright-container {
    display: none; /* Hide menu items below 750px */
  }

  .footer-download {
    margin: 0 auto; /* Center the logo below 750px */
  }
}