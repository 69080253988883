.hello-heading-1 {
    color: #000;
  
    /* Title 1 */
    font-family: Jura;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 0.1;
    align-items: center;
  }


.project-container{
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 120px;
  margin-bottom: 50px;
  margin-top: 50px;
}

.arrow{
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.arrow:hover{
  transform: scale(1.05);
}


.card-project-container{
  display: flex;
  align-items: center;
  gap: 40px;
  flex-direction: row;
  @media (max-width: 1024px) {
    gap: 10px;
}
}

.card-project-1{
  display: flex;
  width: 350px;
  height: 468px;
  padding: 29px 0px;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  border-radius: 25px;
  border: 5px solid #AFB3E0;
  background: #FFF;
  cursor: pointer;
  transition: transform 0.3s ease;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  
}

.card-project-1:hover{
  transform: scale(1.05);
}

.project-image{
  width: 335px;
height: 180px;
flex-shrink: 0;
}

.bottom-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 17px;
}

.vector-project{
  width: 281px;
  height: 3px;
  background-color: #AFB3E0;
}

.project-text-container{
  display: flex;
  width: 326px;
  height: 250px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.project-title, .project-paragraph{
  width: 300px;
height: 38px;
flex-shrink: 0;
color: #000;

/* Title 2 */
font-family: Jura;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin:0;
}

.project-paragraph{
  font-size: 20px;
  font-weight: 450;
  text-align: justify;
  text-justify: inter-word;
}


.background-image-project{
  content: "";
  position: absolute;
  // top: 1500px;
  left: 300px;
  right: 0;
  bottom: -2900px;
  width: 174px;
  height: 156px;
  z-index:-1;
  border-radius: 12px;
  @media (max-width: 1024px) {
          left: 5px;
          top: -3200px;
          width: 133px;
          height: 119px;
    }
}
