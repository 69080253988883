.profile-container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: screen;
  justify-content: space-between;
  gap: 50px;
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
  }
}

.name-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.upper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
}

.hello-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 45px;
  }
}

.hello-heading-1-profile {
  color: #000;

  /* Title 1 */
  font-family: Jura;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 0.1;
  align-items: center;
  @media (max-width: 1024px) {
    line-height: 1;
    margin: 0;
  }
}

.hello-heading-2-profile {
  color: #e28123;

  /* Title 1 */
  font-family: Jura;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 0.1;
  width: 500px;
}

.text-container {
  flex-direction: column;
  align-items: flex-start;
}

.image-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 391.192px;
  height: 392.32px;
  margin-left: 100px;
}

@media (max-width: 1024px) {
  .image-container {
    margin-left: 0px;
  }
}
